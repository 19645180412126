
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import AttachedFileViewer from "@/components/attached-file-viewer.vue";
import { mdiTrashCan } from "@mdi/js";
const visibleProperties = [
  "clientTypeId",
  "caption",
  "firstName",
  "salesRepresentative",
  "patronymic",
  "lastName",
  "comment",
  "phone",
  "phone2",
  "email",
  "paymentTypeId",
  "deliveryMethodId",
  "pickupPointCode",
  "pickupPointName",
  "address",
  "createDate",
  "instanceStateId",
];

@Component({
  components: { AttachedFileViewer },
})
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "order",
  });

  icons: any = {
    delete: mdiTrashCan,
  };

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Заказы",
        to: "/manage/orders/",
        exact: true,
      },
      {
        text: this.dataSource.model?.id,
        disabled: true,
      },
    ];
  }
  get totalSum() {
    const orderItems = this.dataSource?.model?.orderItems;
    const deliveryCost = this.dataSource?.model?.deliveryCost ?? 0;
    if (!(orderItems?.length > 0)) return 0;
    const totalSum =
      orderItems.reduce(
        (s: any, c: any) => s + c?.itemCount * c?.itemPrice,
        0,
      ) + deliveryCost;

    return this.getPrice(totalSum);
  }

  getPrice = (price: any) =>
    price ? `${price.toLocaleString("ru-RU")} ₽` : "";

  get visibleProperties(): any {
    const properties = this.dataSource.metadata?.properties ?? [];
    const cb = (p) => visibleProperties.some((n) => p.name === n);
    return properties.filter(cb);
  }
}
